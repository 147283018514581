import React from 'react';

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-base-200">
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-white mb-8">Terms and Conditions</h1>
        <div className="max-w-4xl bg-base-300/30 backdrop-blur-lg rounded-lg p-8 space-y-6 text-gray-200">
          <div className="prose prose-invert prose-headings:text-white max-w-none">
            <p className="text-sm text-gray-400">Effective Date: November 11, 2024</p>

            <p className="mt-6">
              Welcome to Mockaroni AI! These Terms and Conditions ("Terms") govern your access to and use of our website 
              located at <a href="https://mockaroni.ai" className="text-primary hover:text-primary-focus">https://mockaroni.ai</a> ("Website") 
              and any services provided through the Website (collectively, "Services"). By accessing or using our Website, 
              you agree to be bound by these Terms. If you do not agree to these Terms, please do not access or use our Website.
            </p>

            <h2 className="text-xl font-semibold mt-8 mb-4">1. Eligibility</h2>
            <p>
              To use our Services, you must be at least 18 years old or have the consent of a parent or guardian. 
              By using the Website, you represent and warrant that you meet these eligibility requirements.
            </p>

            <h2 className="text-xl font-semibold mt-8 mb-4">2. Use of Services</h2>
            <p>
              You agree to use the Services only for lawful purposes and in accordance with these Terms. You shall not 
              misuse the Services, including but not limited to, using the Services in a manner that could disable, 
              overburden, or impair the Website or interfere with any other party's use of the Services.
            </p>

            <h2 className="text-xl font-semibold mt-8 mb-4">3. Account Registration</h2>
            <p>
              To access certain features of the Services, you may be required to register for an account. You agree to 
              provide accurate, current, and complete information during the registration process and to update such 
              information to keep it accurate, current, and complete. You are responsible for maintaining the 
              confidentiality of your account credentials and for all activities that occur under your account.
            </p>

            <h2 className="text-xl font-semibold mt-8 mb-4">4. Intellectual Property</h2>
            <p>
              All content, features, and functionality on the Website, including but not limited to text, graphics, 
              logos, and software, are the exclusive property of Mockaroni AI or its licensors and are protected by 
              copyright, trademark, and other intellectual property laws. You may not reproduce, distribute, modify, 
              or create derivative works of any content on the Website without our express written permission.
            </p>

            <h2 className="text-xl font-semibold mt-8 mb-4">5. User Content</h2>
            <p>
              You may submit, upload, or otherwise contribute content to the Website ("User Content"). By submitting 
              User Content, you grant Mockaroni AI a non-exclusive, worldwide, royalty-free, perpetual, and irrevocable 
              license to use, reproduce, modify, publish, and distribute such User Content in connection with the Services. 
              You represent and warrant that you own or have the necessary rights to your User Content and that it does 
              not infringe on any third-party rights.
            </p>

            <h2 className="text-xl font-semibold mt-8 mb-4">6. Disclaimer of Warranties</h2>
            <p>
              The Services are provided on an "as is" and "as available" basis. Mockaroni AI makes no representations 
              or warranties of any kind, express or implied, regarding the operation of the Services, the accuracy of 
              the content, or the results obtained from using the Services. To the fullest extent permitted by law, 
              Mockaroni AI disclaims all warranties, express or implied, including but not limited to implied warranties 
              of merchantability and fitness for a particular purpose.
            </p>

            <h2 className="text-xl font-semibold mt-8 mb-4">7. Limitation of Liability</h2>
            <p>
              To the maximum extent permitted by law, Mockaroni AI shall not be liable for any direct, indirect, 
              incidental, consequential, or punitive damages arising from or related to your use of the Services. 
              This includes, but is not limited to, damages for loss of profits, goodwill, use, data, or other 
              intangible losses.
            </p>

            <h2 className="text-xl font-semibold mt-8 mb-4">8. Indemnification</h2>
            <p>
              You agree to indemnify, defend, and hold harmless Mockaroni AI and its affiliates, officers, directors, 
              employees, and agents from and against any and all claims, liabilities, damages, losses, costs, or 
              expenses (including reasonable attorneys' fees) arising out of or in connection with your use of the 
              Services, your violation of these Terms, or your violation of any rights of another party.
            </p>

            <h2 className="text-xl font-semibold mt-8 mb-4">9. Changes to Terms</h2>
            <p>
              Mockaroni AI reserves the right to modify these Terms at any time. Any changes will be effective 
              immediately upon posting the revised Terms on the Website. Your continued use of the Services after 
              any changes constitutes your acceptance of the new Terms.
            </p>

            <h2 className="text-xl font-semibold mt-8 mb-4">10. Governing Law</h2>
            <p>
              These Terms shall be governed by and construed in accordance with the laws of New York, without 
              regard to its conflict of law principles. Any legal action arising from or relating to these Terms 
              or your use of the Services shall be brought exclusively in the courts located in New York.
            </p>

            <h2 className="text-xl font-semibold mt-8 mb-4">11. Contact Information</h2>
            <p>
              If you have any questions about these Terms, please contact us at:
            </p>
            
            <div className="mt-4 space-y-1">
              <p>Email: <a href="mailto:contact@mockaroni.ai" className="text-primary hover:text-primary-focus">contact@mockaroni.ai</a></p>
              <p>Phone: <a href="tel:4802827167" className="text-primary hover:text-primary-focus">4802827167</a></p>
              <p>Address: 207 E 15th St, New York, NY 10003, United States</p>
            </div>

            <p className="mt-8">
              By using the Services, you acknowledge that you have read, understood, and agree to be bound by these 
              Terms and Conditions. Thank you for using Mockaroni AI!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;