import React from 'react';

const WaveformLoading: React.FC = () => {
  const bars = Array.from({ length: 40 }, (_, i) => i);

  return (
    <div className="h-full w-full flex flex-col items-center justify-center bg-gradient-to-b from-transparent to-blue-50/10">
      {/* Waveform container */}
      <div className="relative h-32 flex items-center justify-center gap-1">
        {bars.map((i) => (
          <div
            key={i}
            className="w-1 rounded-full transform transition-all duration-300"
            style={{
              backgroundColor: '#fbc02b',
              height: '20%',
              animation: `
                waveform ${1 + Math.random() * 0.5}s 
                ease-in-out infinite alternate-reverse
              `,
              animationDelay: `${i * 0.05}s`
            }}
          />
        ))}
      </div>

      {/* Loading text */}
      <div className="mt-12 text-center">
        <p className="text-xl font-medium">
          Generating your interview question
        </p>
        <p className="mt-2 text-sm">This usually takes about 10 seconds</p>
      </div>

      <style>
        {`
          @keyframes waveform {
            0% {
              height: 20%;
              opacity: 0.3;
            }
            50% {
              height: 80%;
              opacity: 0.7;
            }
            100% {
              height: 20%;
              opacity: 0.3;
            }
          }

          @keyframes ellipsis {
            0% { content: '.' }
            33% { content: '..' }
            66% { content: '...' }
            100% { content: '' }
          }
        `}
      </style>
    </div>
  );
};

export default WaveformLoading;