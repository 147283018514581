import { create } from 'zustand';

interface AudioPermissionState {
  audioPermission: boolean | null;
  setAudioPermission: (permission: boolean) => void;
  requestAudioPermission: () => Promise<boolean>;
}

export const useAudioPermissionStore = create<AudioPermissionState>((set) => ({
  audioPermission: (() => {
    const savedPermission = localStorage.getItem('audioPermission');
    return savedPermission ? JSON.parse(savedPermission) : null;
  })(),
  setAudioPermission: (permission: boolean) => {
    localStorage.setItem('audioPermission', JSON.stringify(permission));
    set({ audioPermission: permission });
  },
  requestAudioPermission: async () => {
    try {
      const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
      await audioContext.resume();
      localStorage.setItem('audioPermission', JSON.stringify(true));
      set({ audioPermission: true });
      return true;
    } catch (error) {
      console.error('Error requesting audio permission:', error);
      localStorage.setItem('audioPermission', JSON.stringify(false));
      set({ audioPermission: false });
      return false;
    }
  },
}));