import React from 'react';
import Layout from '../Components/Layout';
import SubscriptionManagement from '../Components/SubscriptionManagement';

const SubscriptionPage = () => {
  return (
    <Layout>
      <div className="max-w-4xl mx-auto p-8">
        <h1 className="text-3xl font-bold mb-8">Subscription Management</h1>
        <SubscriptionManagement />
        <p className="font-bold mb-8">Email contact@mockaroni.ai with any questions or feedback.</p>
      </div>
    </Layout>
  );
};

export default SubscriptionPage;