import React, { useState } from 'react';
import { ArrowRight } from 'lucide-react';

interface JobSelectionComponentProps {
  onSelectJob: (job: string) => void;
  onBack: () => void;
}

const jobRoles = [
  "Data Analyst",
  "Growth Marketer",
  "Project Manager",
  "IT Support",
  "UX Designer",
  "Product Manager",
  "Software Engineer"
];

const JobSelection: React.FC<JobSelectionComponentProps> = ({ onSelectJob, onBack }) => {
  const [selectedJob, setSelectedJob] = useState('');
  const [customJob, setCustomJob] = useState('');

  const handleJobSelect = (job: string) => {
    setSelectedJob(job);
    setCustomJob('');
  };

  const handleCustomJobChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomJob(e.target.value);
    setSelectedJob('');
  };

  const handleSubmit = () => {
    if (selectedJob) {
      onSelectJob(selectedJob);
    } else if (customJob.trim()) {
      onSelectJob(customJob.trim());
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-md">
      <style>
        {`
          @keyframes fadeIn {
            from {
              opacity: 0;
              transform: translateY(10px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
          
          .fade-in-element {
            opacity: 0;
            animation: fadeIn 0.8s ease-out forwards;
          }
          
          .delay-1 { animation-delay: 0.1s; }
          .delay-2 { animation-delay: 0.2s; }
          .delay-3 { animation-delay: 0.3s; }
          .delay-4 { animation-delay: 0.4s; }
          .delay-5 { animation-delay: 0.5s; }
          .delay-6 { animation-delay: 0.6s; }
          .delay-7 { animation-delay: 0.7s; }
          .delay-8 { animation-delay: 0.8s; }
          .delay-9 { animation-delay: 0.9s; }

          .sleek-button {
            background: rgba(255, 255, 255, 0.03);
            backdrop-filter: blur(10px);
            border: 1px solid rgba(255, 255, 255, 0.1);
            transition: all 0.2s ease-in-out;
          }

          .sleek-button:hover {
            background: rgba(255, 255, 255, 0.1);
            border-color: rgba(255, 255, 255, 0.2);
            transform: translateY(-1px);
          }

          .sleek-button.selected {
            background: rgba(255, 255, 255, 0.15);
            border-color: rgba(255, 255, 255, 0.3);
            box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
          }

          .navigation-button {
            background: rgba(255, 255, 255, 0.1);
            backdrop-filter: blur(10px);
            border: none;
            transition: all 0.2s ease-in-out;
          }

          .navigation-button:hover {
            background: rgba(255, 255, 255, 0.2);
            transform: translateY(-1px);
          }

          .navigation-button:disabled {
            background: rgba(255, 255, 255, 0.05);
            opacity: 0.5;
            cursor: not-allowed;
          }
        `}
      </style>
      
      <div className="fade-in-element">
        <h1 className="text-3xl font-bold text-center mb-8">
          What role do you want to practice for?
        </h1>
      </div>
      
      <div className="space-y-2">
        {jobRoles.map((role, index) => (
          <div key={role} className={`fade-in-element delay-${index + 1}`}>
            <button 
              className={`w-full p-4 rounded-lg text-left sleek-button ${selectedJob === role ? 'selected' : ''}`}
              onClick={() => handleJobSelect(role)}
            >
              {role}
            </button>
          </div>
        ))}
        
        <div className="fade-in-element delay-8">
          <div className="relative">
            <input
              type="text"
              placeholder="Or enter a custom role..."
              className="w-full p-4 rounded-lg bg-opacity-10 bg-white backdrop-blur-md border border-white/10 text-center focus:outline-none focus:border-white/20 transition-all"
              value={customJob}
              onChange={handleCustomJobChange}
            />
          </div>
        </div>
      </div>
      
      <div className="fade-in-element delay-9">
        <div className="mt-8 flex justify-between gap-4">
          <button 
            className="navigation-button px-6 py-3 rounded-lg"
            onClick={onBack}
          >
            Back
          </button>
          <button 
            className="navigation-button px-6 py-3 rounded-lg"
            onClick={handleSubmit}
            disabled={!selectedJob && !customJob.trim()}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default JobSelection;