import React, { useEffect, createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu } from 'lucide-react';
import SideBar from './SideBar';
import { useUserStore } from '../ZustandStores/userStore';
import { useQuestionStore } from '../ZustandStores/questionsStore';
import { supabase } from '../lib/supabaseClient';

interface LayoutContextType {
  refreshQuestions: () => void;
}

const LayoutContext = createContext<LayoutContextType | undefined>(undefined);

export const useLayout = () => {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error('useLayout must be used within a LayoutProvider');
  }
  return context;
};

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const { user, signOut } = useUserStore();
  const { recentQuestions, fetchRecentQuestions } = useQuestionStore();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const refreshQuestions = () => {
    if (user) {
      fetchRecentQuestions(user.id);
    }
  };

  useEffect(() => {
    if (user) {
      fetchRecentQuestions(user.id);

      const channel = supabase
        .channel('questions_changes')
        .on('postgres_changes', 
          { event: '*', schema: 'public', table: 'questions', filter: `user_id=eq.${user.id}` },
          (payload) => {
            console.log('Questions change received:', payload);
            refreshQuestions();
          }
        )
        .subscribe();

      return () => {
        supabase.removeChannel(channel);
      };
    }
  }, [user]);

  const handleSignOut = async () => {
    await signOut();
    navigate('/');
  };

  return (
    <LayoutContext.Provider value={{ refreshQuestions }}>
      <div className="flex h-screen">
        <div className="hidden lg:block">
          <SideBar questions={recentQuestions} onSignOut={handleSignOut} />
        </div>

        <div className="flex-1 overflow-auto">
          {children}
        </div>

        <button 
          className="lg:hidden fixed top-4 left-4 btn btn-circle"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <Menu />
        </button>

        {isMenuOpen && (
          <div className="lg:hidden fixed inset-0 bg-gray-600 bg-opacity-75 z-40">
            <div className="fixed inset-y-0 left-0 w-64 bg-white shadow-lg z-50">
              <SideBar questions={recentQuestions} onSignOut={handleSignOut} />
            </div>
          </div>
        )}
      </div>
    </LayoutContext.Provider>
  );
};

export default Layout;