import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronDown, PlusCircle, ArrowRight, CreditCard } from 'lucide-react';
import { useUserStore } from '../ZustandStores/userStore';
import mockaroniLogo from '../Assets/mockaroni-logo.webp';
import { useSubscription } from '../Components/SubscriptionModal';
import { track } from '../lib/mixpanel';



interface Question {
  id: string;
  job_title: string;
  company: string;
  created_at: string;
  status: string;
  summary: string;
}

interface SideBarProps {
  questions: Question[];
  onSignOut: () => void;
}

const SideBar: React.FC<SideBarProps> = ({ questions, onSignOut }) => {
  const navigate = useNavigate();
  const { user } = useUserStore();
  const { subscriptionStatus } = useSubscription();

  const handleNewQuestion = () => {
    track('New Question Clicked', {
      source: 'sidebar',
      location: 'top_button',
      subscription_status: subscriptionStatus?.subscription_status,
      path: window.location.pathname
    });
    navigate('/home');
  };

  const handleQuestionClick = (question: Question) => {
    track('Question Selected', {
      source: 'sidebar',
      question_id: question.id,
      question_status: question.status,
      job_title: question.job_title,
      company: question.company,
      question_age: Date.now() - new Date(question.created_at).getTime(),
      subscription_status: subscriptionStatus?.subscription_status
    });
    navigate(`/question/${question.id}`);
  };

  const handleViewAllQuestions = () => {
    track('View All Questions Clicked', {
      source: 'sidebar',
      recent_questions_count: questions.length,
      subscription_status: subscriptionStatus?.subscription_status
    });
    navigate('/allQuestions');
  };

  const handleSubscriptionClick = () => {
    track('Subscription Menu Clicked', {
      source: 'sidebar',
      current_status: subscriptionStatus?.subscription_status,
      subscription_tier: subscriptionStatus?.subscription_tier,
      has_past_due: subscriptionStatus?.subscription_status === 'past_due',
      days_until_expiry: subscriptionStatus?.subscription_end_date ? 
        Math.ceil((new Date(subscriptionStatus.subscription_end_date).getTime() - Date.now()) / (1000 * 60 * 60 * 24)) : 
        null
    });
    navigate('/subscription');
  };

  const handleSignOut = () => {
    track('Sign Out Clicked', {
      subscription_status: subscriptionStatus?.subscription_status,
    });
    onSignOut();
  };
  
  // Take only the 5 most recent questions
  const recentQuestions = questions.slice(0, 5);

  return (
    <div className="w-64 bg-base-200/30 backdrop-blur-lg text-base-content shadow-lg flex flex-col h-full">
      <style>
        {`
          .sleek-question {
            background: rgba(255, 255, 255, 0.03);
            border: 1px solid rgba(255, 255, 255, 0.1);
            transition: all 0.2s ease-in-out;
          }

          .sleek-question:hover {
            border-color: rgba(255, 255, 255, 0.3);
            transform: translateY(-1px);
          }

          .new-question-button {
            background: #fbc02b;
            transition: all 0.2s ease-in-out;
            box-shadow: 0 2px 4px rgba(251, 192, 43, 0.2);
          }

          .new-question-button:hover {
            transform: translateY(-1px);
            box-shadow: 0 4px 12px rgba(251, 192, 43, 0.3);
          }

          .new-question-button:active {
            transform: translateY(0px);
            box-shadow: 0 2px 4px rgba(251, 192, 43, 0.2);
          }

          .tag {
            background: rgba(255, 255, 255, 0.1);
            color: rgba(255, 255, 255, 0.9);
          }
        `}
      </style>

      {/* Logo and Brand Section */}
      <div className="p-4 border-b border-white/10">
        <div className="flex items-center gap-2">
          <img src={mockaroniLogo} alt="Mockaroni Logo" className="w-8 h-8" />
          <h1 className="text-xl font-bold text-white">Mockaroni</h1>
        </div>
      </div>

      <div className="p-4 flex-grow overflow-y-auto">
        <h2 className="text-xl font-bold mb-4 text-white">Recents</h2>
        <ul className="space-y-3">
          <li>
            <button
              className="w-full text-left p-4 rounded-lg new-question-button flex items-center text-stone-900 font-medium"
              onClick={handleNewQuestion}
            >
              <PlusCircle size={18} className="mr-2" />
              <span>Answer a new question</span>
            </button>
          </li>
          {recentQuestions.map((question) => (
            <li key={question.id}>
              <button
                className="w-full text-left p-4 rounded-lg sleek-question"
                onClick={() => handleQuestionClick(question)}
              >
                <div className="flex items-center mb-2">
                  <p className="text-sm font-medium text-white/90">
                    {question.summary}
                  </p>
                </div>
                <div className="flex flex-wrap gap-2 mt-2">
                  <span className="tag px-3 py-1 rounded-full text-xs font-medium">
                    {question.job_title}
                  </span>
                  <span className="tag px-3 py-1 rounded-full text-xs font-medium">
                    {question.company}
                  </span>
                </div>
              </button>
            </li>
          ))}
          <li>
            <button
              onClick={handleViewAllQuestions}
              className="w-full text-left p-4 rounded-lg sleek-question flex items-center justify-between text-white/90 hover:text-white"
            >
              <span>View all questions</span>
              <ArrowRight size={16} />
            </button>
          </li>
        </ul>
      </div>

      <div className="p-4 bg-base-200/50 border-t border-white/10">
        <div className="dropdown dropdown-top w-full">
          <button tabIndex={0} className="btn btn-ghost btn-block justify-between">
            <span className="truncate">{user?.email}</span>
            <ChevronDown size={20} />
          </button>
          <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
            <li>
              <button 
                onClick={handleSubscriptionClick}
                className="flex items-center gap-2"
              >
                <CreditCard size={16} />
                <span>Subscription</span>
                {subscriptionStatus?.subscription_status === 'past_due' && (
                  <span className="badge badge-sm badge-warning ml-auto">!</span>
                )}
              </button>
            </li>
            <li><button onClick={handleSignOut}>Log out</button></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideBar;