import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '../ZustandStores/userStore';
import { useQuestionStore } from '../ZustandStores/questionsStore';
import { useAudioPermissionStore } from '../ZustandStores/useAudioPermissionsStore';
import { AudioLines } from 'lucide-react';
import JobSelection from '../Components/JobSelection';
import CompanySelection from '../Components/CompanySelection';
import { supabase } from '../lib/supabaseClient';
import { v4 as uuidv4 } from 'uuid';
import Layout from '../Components/Layout';
import { Question } from '../types/types';
import mockaroniLogo from '../Assets/mockaroni-logo.webp';
import { SubscriptionModal, useSubscription } from '../Components/SubscriptionModal';

const getTimeOfDay = (): string => {
  const hour = new Date().getHours();
  if (hour >= 5 && hour < 12) return 'morning';
  if (hour >= 12 && hour < 17) return 'afternoon';
  return 'evening';
};

const MockaroniHomePageContent: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useUserStore();
  const { addQuestion } = useQuestionStore();
  const [showJobSelection, setShowJobSelection] = useState(false);
  const [showCompanySelection, setShowCompanySelection] = useState(false);
  const [selectedJob, setSelectedJob] = useState('');
  const [selectedCompany, setSelectedCompany] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { audioPermission, requestAudioPermission } = useAudioPermissionStore();
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const { subscriptionStatus, isLoading: isLoadingSubscription } = useSubscription();

  const checkSubscriptionAccess = useCallback(async () => {
    if (!subscriptionStatus) return false;
    
    if (!subscriptionStatus.first_question_used) return true;
    return subscriptionStatus.subscription_status === 'active';
  }, [subscriptionStatus]);

  const handleNewAudioQuestion = async () => {
    if (isLoading || isLoadingSubscription) {
      return;
    }

    try {
      setIsLoading(true);

      const hasAccess = await checkSubscriptionAccess();
      
      if (!hasAccess) {
        setShowSubscriptionModal(true);
        return;
      }

      if (audioPermission === null) {
        const permissionGranted = await requestAudioPermission();
        if (!permissionGranted) {
          alert("Audio permission is required for the best experience. Please enable it in your browser settings.");
          return;
        }
      }

      setShowJobSelection(true);
    } catch (error) {
      console.error('Error in handleNewAudioQuestion:', error);
      alert('Something went wrong. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleJobSelection = useCallback((job: string) => {
    setSelectedJob(job);
    setShowJobSelection(false);
    setShowCompanySelection(true);
  }, []);

  const createQuestionAndNavigate = useCallback(async (company: string) => {
    if (!user?.id) {
      console.error('No user ID found');
      return;
    }

    setIsLoading(true);

    try {
      const questionId = uuidv4();
      const firstName = user.full_name ? user.full_name.split(' ')[0] : '';
      
      const newQuestion: Question = {
        id: questionId,
        user_id: user.id,
        job_title: selectedJob,
        company: company,
        status: 'generating',
        summary: '',
        question: '',
        created_at: new Date().toISOString(),
        audio_url: null
      };

      const { error: insertError } = await supabase
        .from('questions')
        .insert(newQuestion);

      if (insertError) throw insertError;

      addQuestion(newQuestion);
      navigate(`/question/${questionId}`);

      await supabase.functions.invoke('generate-audio-question', {
        body: JSON.stringify({ 
          questionId,
          firstName,
          jobTitle: selectedJob,
          company
        }),
      });
    } catch (error) {
      console.error('Error creating question:', error);
      alert('Failed to create question. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [user, selectedJob, navigate, addQuestion]);

  const handleCompanySelection = useCallback(async (company: string) => {
    setSelectedCompany(company);
    setShowCompanySelection(false);
    await createQuestionAndNavigate(company);
  }, [createQuestionAndNavigate]);

  const handleBack = useCallback(() => {
    if (showCompanySelection) {
      setShowCompanySelection(false);
      setShowJobSelection(true);
    } else if (showJobSelection) {
      setShowJobSelection(false);
    }
  }, [showCompanySelection, showJobSelection]);

  const handleSubscriptionSuccess = useCallback(() => {
    setShowSubscriptionModal(false);
    setShowJobSelection(true);
  }, []);

  const firstName = user?.full_name ? user.full_name.split(' ')[0] : '';
  const timeOfDay = getTimeOfDay();

  return (
    <div className="h-full flex items-center justify-center">
      <style>
        {`
          @keyframes fadeIn {
            from {
              opacity: 0;
              transform: translateY(10px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
          
          .fade-in-1 {
            opacity: 0;
            animation: fadeIn 0.8s ease-out forwards;
          }
        `}
      </style>

      <SubscriptionModal 
        isOpen={showSubscriptionModal}
        onClose={() => setShowSubscriptionModal(false)}
        onSuccess={handleSubscriptionSuccess}
      />
      
      {showJobSelection ? (
        <JobSelection 
          onSelectJob={handleJobSelection}
          onBack={handleBack}
        />
      ) : showCompanySelection ? (
        <CompanySelection 
          onSelectCompany={handleCompanySelection}
          onBack={handleBack}
        />
      ) : (
        <div className="flex flex-col items-center justify-center gap-8">
          <div className="flex items-center gap-4 fade-in-1">
            <img 
              src={mockaroniLogo} 
              alt="Mockaroni Logo" 
              className="h-16 w-auto"
            />
            <h1 className="text-4xl font-bold">
              Good {timeOfDay}, {firstName}. Ready to practice?
            </h1>
          </div>
          <button 
            className={`btn btn-primary flex items-center ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={handleNewAudioQuestion}
            disabled={isLoading || isLoadingSubscription}
          >
            <AudioLines className="mr-2" />
            {isLoading ? 'Processing...' : 'New Question'}
          </button>
        </div>
      )}
    </div>
  );
};

const MockaroniHomePage: React.FC = () => (
  <Layout>
    <MockaroniHomePageContent />
  </Layout>
);

export default MockaroniHomePage;