import React from 'react';
import { useSubscription } from './SubscriptionModal';
import { supabase } from '../lib/supabaseClient';
import { CreditCard, AlertTriangle } from 'lucide-react';

const SubscriptionManagement = () => {
  const { subscriptionStatus, isLoading } = useSubscription();
  const [isRedirecting, setIsRedirecting] = React.useState(false);

  const handleManageSubscription = async () => {
    try {
      setIsRedirecting(true);
      const origin = window.location.origin;
      
      const { data, error } = await supabase.functions.invoke('create-portal-session', {
        body: { 
          returnUrl: `${origin}/subscription` 
        }
      });
      
      if (error) throw error;
      if (data?.url) {
        window.location.href = data.url;
      }
    } catch (error) {
      console.error('Error creating portal session:', error);
      alert('Failed to open subscription management. Please try again.');
    } finally {
      setIsRedirecting(false);
    }
  };

  if (isLoading) {
    return <div className="loading loading-spinner"></div>;
  }

  return (
    <div className="card bg-base-100 shadow-xl">
      <div className="card-body">
        <h2 className="card-title">Subscription Status</h2>
        
        <div className="mt-4">
          <div className="flex items-center gap-2">
            <div className={`w-3 h-3 rounded-full ${
              subscriptionStatus?.subscription_status === 'active' ? 'bg-success' :
              subscriptionStatus?.subscription_status === 'past_due' ? 'bg-warning' :
              'bg-error'
            }`} />
            <span className="capitalize">
              {subscriptionStatus?.subscription_status || 'No subscription'}
            </span>
          </div>

          {subscriptionStatus?.subscription_status === 'past_due' && (
            <div className="alert alert-warning mt-4">
              <AlertTriangle className="w-5 h-5" />
              <div>
                <h3 className="font-bold">Payment Failed</h3>
                <p className="text-sm">
                  Your last payment failed. Please update your payment method to continue using our services.
                </p>
              </div>
            </div>
          )}

          {subscriptionStatus?.subscription_end_date && (
            <p className="text-sm mt-2">
              Next billing date: {new Date(subscriptionStatus.subscription_end_date).toLocaleDateString()}
            </p>
          )}
        </div>

        <div className="card-actions justify-end mt-4">
          <button
            className="btn btn-primary"
            onClick={handleManageSubscription}
            disabled={isRedirecting}
          >
            <CreditCard className="w-4 h-4 mr-2" />
            {isRedirecting ? 'Redirecting...' : 'Manage Subscription'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionManagement;