import { create } from 'zustand';
import { supabase } from '../lib/supabaseClient';
import { Question } from '../types/types';

interface QuestionState {
  questions: Question[];
  recentQuestions: Question[];
  isLoading: boolean;
  error: string | null;
  fetchQuestions: (userId: string) => Promise<void>;
  fetchRecentQuestions: (userId: string) => Promise<void>;
  addQuestion: (newQuestion: Question) => void;
  updateQuestion: (updatedQuestion: Question) => void;
  setError: (error: string | null) => void;
}

export const useQuestionStore = create<QuestionState>((set, get) => ({
  questions: [],
  recentQuestions: [],
  isLoading: false,
  error: null,

  fetchQuestions: async (userId: string) => {
    set({ isLoading: true });
    try {
      const { data, error } = await supabase
        .from('questions')
        .select('*')
        .eq('user_id', userId)
        .order('created_at', { ascending: false });

      if (error) throw error;
      set({ questions: data as Question[], isLoading: false, error: null });
    } catch (error) {
      set({ 
        isLoading: false, 
        error: error instanceof Error ? error.message : 'An unknown error occurred'
      });
    }
  },

  fetchRecentQuestions: async (userId: string) => {
    set({ isLoading: true });
    try {
      const { data, error } = await supabase
        .from('questions')
        .select('*')
        .eq('user_id', userId)
        .order('created_at', { ascending: false })
        .limit(5);

      if (error) throw error;
      set({ recentQuestions: data as Question[], isLoading: false, error: null });
    } catch (error) {
      set({ 
        isLoading: false, 
        error: error instanceof Error ? error.message : 'An unknown error occurred'
      });
    }
  },

  addQuestion: (newQuestion: Question) => {
    set(state => ({
      questions: [newQuestion, ...state.questions],
      recentQuestions: [newQuestion, ...state.recentQuestions].slice(0, 5)
    }));
  },

  updateQuestion: (updatedQuestion: Question) => {
    set(state => ({
      questions: state.questions.map(q => 
        q.id === updatedQuestion.id ? updatedQuestion : q
      ),
      recentQuestions: state.recentQuestions.map(q => 
        q.id === updatedQuestion.id ? updatedQuestion : q
      )
    }));
  },

  setError: (error: string | null) => set({ error }),
}));