import React from 'react';
import { ThumbsUp, ThumbsDown, Lightbulb } from 'lucide-react';

interface FeedbackDisplayProps {
  feedback: {
    pros: string[];
    cons: string[];
    improvements: string[];
    overall_score: number;
  };
}

const FeedbackDisplay: React.FC<FeedbackDisplayProps> = ({ feedback }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
      {/* Pros Card */}
      <div className="bg-base-100/30 backdrop-blur-sm rounded-2xl overflow-hidden border border-success/5 hover:border-success/10 transition-all duration-300">
        <div className="p-6 space-y-6">
          <div className="flex items-center gap-2 text-success/90">
            <div className="bg-success/10 p-2 rounded-lg">
              <ThumbsUp className="h-5 w-5" />
            </div>
            <h2 className="font-semibold text-base">Strengths</h2>
          </div>
          <ul className="space-y-4">
            {feedback.pros.map((pro, index) => (
              <li key={index} className="flex gap-3 text-base-content/80 text-sm">
                <div className="w-1.5 h-1.5 rounded-full bg-success/40 mt-2"></div>
                <span>{pro}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Cons Card */}
      <div className="bg-base-100/30 backdrop-blur-sm rounded-2xl overflow-hidden border border-error/5 hover:border-error/10 transition-all duration-300">
        <div className="p-6 space-y-6">
          <div className="flex items-center gap-2 text-error/90">
            <div className="bg-error/10 p-2 rounded-lg">
              <ThumbsDown className="h-5 w-5" />
            </div>
            <h2 className="font-semibold text-base">Areas for Improvement</h2>
          </div>
          <ul className="space-y-4">
            {feedback.cons.map((con, index) => (
              <li key={index} className="flex gap-3 text-base-content/80 text-sm">
                <div className="w-1.5 h-1.5 rounded-full bg-error/40 mt-2"></div>
                <span>{con}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Improvements Card */}
      <div className="bg-base-100/30 backdrop-blur-sm rounded-2xl overflow-hidden border border-info/5 hover:border-info/10 transition-all duration-300">
        <div className="p-6 space-y-6">
          <div className="flex items-center gap-2 text-info/90">
            <div className="bg-info/10 p-2 rounded-lg">
              <Lightbulb className="h-5 w-5" />
            </div>
            <h2 className="font-semibold text-base">Suggestions</h2>
          </div>
          <ul className="space-y-4">
            {feedback.improvements.map((improvement, index) => (
              <li key={index} className="flex gap-3 text-base-content/80 text-sm">
                <div className="w-1.5 h-1.5 rounded-full bg-info/40 mt-2"></div>
                <span>{improvement}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FeedbackDisplay;