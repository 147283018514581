import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Question } from '../types/types';
import { track } from '../lib/mixpanel';


interface AllQuestionsQuestionCardProps {
  question: Question;
}

const AllQuestionsQuestionCard: React.FC<AllQuestionsQuestionCardProps> = ({ question }) => {
  const navigate = useNavigate();

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
  };

  const getStatusColor = (status: Question['status']) => {
    switch (status) {
      case 'completed':
        return 'bg-green-500/20 text-green-300';
      case 'generating':
        return 'bg-yellow-500/20 text-yellow-300';
      case 'failed':
        return 'bg-red-500/20 text-red-300';
      case 'pending':
      default:
        return 'bg-gray-500/20 text-gray-300';
    }
  };

  const handleQuestionClick = () => {
    // Track the question click event
    track('Question Clicked', {
      question_id: question.id,
      job_title: question.job_title,
      company: question.company,
      status: question.status,
      has_audio: !!question.audio_url,
      created_at: question.created_at,
      source: 'all_questions_page',
      time_since_creation: Date.now() - new Date(question.created_at).getTime(),
    });

    // Navigate to the question page
    navigate(`/question/${question.id}`);
  };


  return (
    <button
      className="text-left p-4 rounded-lg sleek-question w-full"
      onClick={handleQuestionClick}
    >
      <div className="flex flex-col h-full">
        <div className="flex-grow">
          <p className="text-sm font-medium text-white/90 mb-2">
            {question.summary}
          </p>
          <div className="flex flex-wrap gap-2 mb-3">
            <span className="tag px-3 py-1 rounded-full text-xs font-medium">
              {question.job_title}
            </span>
            <span className="tag px-3 py-1 rounded-full text-xs font-medium">
              {question.company}
            </span>
          </div>
        </div>
        <div className="text-xs text-white/60">
          Created: {formatDate(question.created_at)}
        </div>
      </div>
    </button>
  );
};

export default AllQuestionsQuestionCard;