import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabaseClient';
import Layout from '../Components/Layout';
import { Monitor } from 'lucide-react';
import { useAudioPermissionStore } from '../ZustandStores/useAudioPermissionsStore';
import { useQuestionStore } from '../ZustandStores/questionsStore';
import { Question } from '../types/types';
import QuestionCard from '../Components/QuestionCard';
import AttemptDisplay from '../Components/AttemptDisplay';
import WaveformLoading from '../Components/WaveformLoading';
import ProcessingAnimation from '../Components/ProcessingAnimation';
import { SubscriptionModal } from '../Components/SubscriptionModal';
import { track } from '../lib/mixpanel';

interface Answer {
  id: string;
  audio_url: string;
  transcription: string;
  feedback: {
    pros: string[];
    cons: string[];
    improvements: string[];
    overall_score: number;
  };
  created_at: string;
  status: string;
}

const QuestionPageContent: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [question, setQuestion] = useState<Question | null>(null);
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isAudioFinished, setIsAudioFinished] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const { audioPermission, requestAudioPermission } = useAudioPermissionStore();
  const { questions, updateQuestion } = useQuestionStore();
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [audioPlayCount, setAudioPlayCount] = useState(0);
  const [initialViewTime] = useState(Date.now());
  const [submissionStartTime, setSubmissionStartTime] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

   // Add this cleanup effect
   useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, [id]); // Run cleanup when question ID changes

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  // Track initial page view
  useEffect(() => {
    if (question) {
      track('Question Page Viewed', {
        question_id: question.id,
        job_title: question.job_title,
        company: question.company,
        status: question.status,
        has_audio: !!question.audio_url,
        existing_attempts: answers.length,
        from_page: document.referrer
      });
    }
  }, [question?.id, answers.length]);
  
  const handleShowSubscriptionModal = useCallback(() => {
    track('Subscription Modal Opened', {
      source: 'question_page',
      question_id: question?.id,
      current_attempts: answers.length
    });
    setShowSubscriptionModal(true);
  }, [question?.id, answers.length]);

  const handleCloseSubscriptionModal = useCallback(() => {
    track('Subscription Modal Closed', {
      source: 'question_page',
      question_id: question?.id,
      time_open: Date.now() - submissionStartTime
    });
    setShowSubscriptionModal(false);
  }, [question?.id, submissionStartTime]);

  const handleSubscriptionSuccess = useCallback(async () => {
    track('Subscription Success', {
      source: 'question_page',
      question_id: question?.id
    });
    setShowSubscriptionModal(false);
  }, [question?.id]);

  const toggleMute = useCallback(() => {
    if (audioRef.current) {
      const newMutedState = !audioRef.current.muted;
      audioRef.current.muted = newMutedState;
      setIsMuted(newMutedState);
      
      track('Audio Mute Toggled', {
        question_id: question?.id,
        action: newMutedState ? 'muted' : 'unmuted',
        current_time: audioRef.current.currentTime,
        total_duration: audioRef.current.duration
      });
    }
  }, [question?.id]);

  const repeatQuestion = useCallback(() => {
    if (audioRef.current) {
      track('Question Audio Repeated', {
        question_id: question?.id,
        previous_plays: audioPlayCount,
        total_attempts: answers.length
      });
      
      audioRef.current.currentTime = 0;
      audioRef.current.play().catch(error => {
        track('Audio Playback Error', {
          question_id: question?.id,
          error_message: error.message
        });
        console.error(error);
      });
      setIsAudioFinished(false);
      setAudioPlayCount(prev => prev + 1);
    }
  }, [question?.id, audioPlayCount, answers.length]);

  const handleAudioEnded = useCallback(() => {
    setIsAudioFinished(true);
    track('Question Audio Completed', {
      question_id: question?.id,
      duration: audioRef.current?.duration,
      play_count: audioPlayCount + 1
    });
  }, [question?.id, audioPlayCount]);

  const fetchAnswers = useCallback(async () => {
    if (!id) return;

    try {
      const { data, error } = await supabase
        .from('answers')
        .select('*')
        .eq('question_id', id)
        .order('created_at', { ascending: false });

      if (error) {
        track('Answers Fetch Error', {
          question_id: id,
          error_message: error.message
        });
        throw error;
      }
      
      track('Answers Fetched', {
        question_id: id,
        answers_count: data.length
      });
      
      setAnswers(data as Answer[]);
    } catch (error) {
      console.error('Error fetching answers:', error);
    }
  }, [id]);

  const fetchQuestion = useCallback(async () => {
    if (!id) {
      navigate('/home');
      return;
    }

    try {
      const { data, error } = await supabase
        .from('questions')
        .select('*')
        .eq('id', id)
        .single();

      if (error) {
        track('Question Fetch Error', {
          question_id: id,
          error_message: error.message
        });
        throw error;
      }

      const fetchedQuestion = data as Question;
      setQuestion(fetchedQuestion);
      updateQuestion(fetchedQuestion);
      
      if (fetchedQuestion.status === 'generating') {
        setIsGenerating(true);
        track('Question Generating', {
          question_id: id
        });
      }
      
      setIsPageLoading(false);
    } catch (error) {
      console.error('Error fetching question:', error);
      setIsPageLoading(false);
    }
  }, [id, navigate, updateQuestion]);

  const setupAudio = useCallback(async () => {
    if (!question?.audio_url || !audioRef.current) return;

    if (audioPermission === null) {
      track('Audio Permission Requested', {
        question_id: question.id
      });
      
      const granted = await requestAudioPermission();
      
      track('Audio Permission Response', {
        question_id: question.id,
        granted: granted
      });
      
      if (!granted) return;
    }

    if (audioPermission) {
      audioRef.current.src = question.audio_url;
      audioRef.current.load();
      audioRef.current.play().catch(error => {
        track('Audio Setup Error', {
          question_id: question.id,
          error_message: error.message
        });
        console.error(error);
      });
    }
  }, [question?.audio_url, audioPermission, requestAudioPermission, question?.id]);

  useEffect(() => {
    fetchQuestion();
    fetchAnswers();
  }, [fetchQuestion, fetchAnswers]);

  useEffect(() => {
    if (!id) return;

    const channel = supabase
      .channel(`question_${id}`)
      .on(
        'postgres_changes',
        {
          event: 'UPDATE',
          schema: 'public',
          table: 'questions',
          filter: `id=eq.${id}`,
        },
        (payload) => {
          const updatedQuestion = payload.new as Question;
          setQuestion(updatedQuestion);
          updateQuestion(updatedQuestion);
          
          if (updatedQuestion.status === 'completed') {
            track('Question Generation Completed', {
              question_id: id
            });
            setIsGenerating(false);
          } else if (updatedQuestion.status === 'failed') {
            track('Question Generation Failed', {
              question_id: id
            });
            setIsGenerating(false);
            alert('Failed to generate question. Please try again.');
          }
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [id, updateQuestion]);

  useEffect(() => {
    if (!id) return;

    const answersSubscription = supabase
      .channel('answers_changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'answers',
          filter: `question_id=eq.${id}`,
        },
        (payload) => {
          track('Answer Updated', {
            question_id: id,
            event_type: payload.eventType,
            answer_status: (payload.new as Answer)?.status
          });
          fetchAnswers();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(answersSubscription);
    };
  }, [id, fetchAnswers]);

  useEffect(() => {
    if (question?.audio_url) {
      setupAudio();
    }
  }, [question?.audio_url, setupAudio]);

  if (isPageLoading) {
    return (
      <div className="h-full w-full flex items-center justify-center">
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    );
  }

  if (isMobile) {
    return (
      <div className="fixed inset-0 bg-white z-50 flex items-center justify-center p-4">
        <div className="text-center max-w-md">
          <Monitor className="w-16 h-16 mx-auto mb-6 text-blue-500" />
          <h2 className="text-2xl font-bold mb-4">Switch to Desktop</h2>
          <p className="text-lg text-gray-600">
            Switch to your laptop for the best way to practice with Mockaroni AI!
          </p>
        </div>
      </div>
    );
  }

  if (isGenerating) {
    return <WaveformLoading />;
  }

  if (!question) {
    track('Question Not Found', {
      attempted_question_id: id
    });
    
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <h1 className="text-2xl font-bold mb-4">Error</h1>
        <p className="text-red-500">Question not found.</p>
        <button
          className="mt-4 btn btn-primary"
          onClick={() => navigate('/home')}
        >
          Back to Home
        </button>
      </div>
    );
  }

  return (
    <div className="relative h-full">
      <SubscriptionModal 
        isOpen={showSubscriptionModal}
        onClose={handleCloseSubscriptionModal}
        onSuccess={handleSubscriptionSuccess}
      />
      <div className="sticky top-0 left-0 right-0 z-10 border-b shadow-md">
        <QuestionCard
          key={question?.id} // This is crucial for forcing remount
          question={question}
          isLoading={isPageLoading}
          isMuted={isMuted}
          isAudioFinished={isAudioFinished}
          audioRef={audioRef}
          toggleMute={toggleMute}
          repeatQuestion={repeatQuestion}
          handleAudioEnded={handleAudioEnded}
          fetchQuestion={fetchQuestion}
          audioPermission={audioPermission}
          requestAudioPermission={requestAudioPermission}
          onShowSubscriptionModal={handleShowSubscriptionModal}
        />
      </div>

      <div className="p-4">
        {answers.some(answer => answer.status === 'processing') && (
          <ProcessingAnimation />
        )}

        <div className="space-y-8">
          {answers
            .filter(answer => answer.status === 'completed')
            .map((answer, index) => (
              <div key={answer.id} className="w-full">
                <AttemptDisplay 
                  answer={answer}
                  attemptNumber={answers.length - index}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

const QuestionPage: React.FC = () => (
  <Layout>
    <style>{`
      .subscription-modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `}</style>
    <QuestionPageContent />
  </Layout>
);

export default QuestionPage;