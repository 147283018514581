import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { supabase } from './lib/supabaseClient';
import LandingPage from './Pages/LandingPage';
import AuthenticatePage from './Pages/AuthenticatePage';
import MockaroniHomePage from './Pages/MockaroniHomePage';
import QuestionPage from './Pages/QuestionPage';
import AllQuestionsPage from './Pages/AllQuestionsPage';
import SubscriptionPage from './Pages/SubscriptionPage';
import PrivacyPolicy from './Pages/PrivacyPage';
import TermsOfService from './Pages/TermsOfServicePage';
import { useUserStore } from './ZustandStores/userStore';
import { useTracking } from './lib/mixpanel';


const Spinner = () => (
  <div className="fixed inset-0 flex items-center justify-center">
    <span className="loading loading-spinner loading-md"></span>
  </div>
);

function App() {
  const { session, user, isLoading, hasFetchedUser, setSession, setIsLoading, fetchUserData, setHasFetchedUser } = useUserStore();
  const [initialCheckDone, setInitialCheckDone] = useState(false);
  const initRef = useRef(false);

  useTracking(
    user?.id, // Pass user ID when available
    user ? {
      email: user.email,
      // Add any other user properties you want to track
      subscription_status: user.subscription_status,
    } : undefined
  );

  // Effect for session check
  useEffect(() => {
    const checkSession = async () => {
      if (initRef.current) return;
      initRef.current = true;

      setIsLoading(true);
      const { data: { session } } = await supabase.auth.getSession();
      setSession(session);
      setIsLoading(false);
      setInitialCheckDone(true);
    };

    checkSession();
  }, [setSession, setIsLoading]);

  // Effect for user data fetch
  useEffect(() => {
    if (session && !hasFetchedUser) {
      fetchUserData().then(() => setHasFetchedUser(true));
    }
  }, [session, hasFetchedUser, fetchUserData, setHasFetchedUser]);

  // Effect for auth state change
  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, [setSession]);

  if (isLoading || !initialCheckDone) {
    return <Spinner />;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/auth" element={session ? <Navigate to="/home" replace /> : <AuthenticatePage />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="termsOfService" element={<TermsOfService />} />
        <Route
          path="/subscription"
          element={
            session && user ? (
              <SubscriptionPage />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/home"
          element={
            session && user ? (
              <MockaroniHomePage />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/question/:id"
          element={
            session && user ? (
              <QuestionPage />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="/allQuestions"
          element={
            session && user ? (
              <AllQuestionsPage />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
      </Routes>
    </Router>
  );
}

export default App;