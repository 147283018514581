import { create } from 'zustand';
import { supabase } from '../lib/supabaseClient';
import { Session } from '@supabase/supabase-js';
import { reset } from '../lib/mixpanel';


interface User {
  id: string;
  full_name: string;
  email: string;
  job_title: string;
  subscription_status: string | null;
  subscription_tier: string | null;
  subscription_end_date: string | null;
  first_question_used: boolean | null;
  first_answer_used: boolean | null;
  stripe_customer_id: string | null;
}

interface UserState {
  session: Session | null;
  user: User | null;
  isLoading: boolean;
  hasFetchedUser: boolean;
  setSession: (session: Session | null) => void;
  setUser: (user: User | null) => void;
  setIsLoading: (isLoading: boolean) => void;
  setHasFetchedUser: (fetched: boolean) => void;
  signOut: () => Promise<void>;
  fetchUserData: () => Promise<void>;
  updateUserData: (data: Partial<User>) => Promise<void>;
  error: string | null;
  setError: (error: string | null) => void;
}

export const useUserStore = create<UserState>((set, get) => ({
  session: null,
  user: null,
  isLoading: true,
  hasFetchedUser: false,
  error: null,
  setError: (error) => set({ error }),

  setSession: (session) => set({ session }),
  setUser: (user) => set({ user }),
  setIsLoading: (isLoading) => set({ isLoading }),
  setHasFetchedUser: (fetched) => set({ hasFetchedUser: fetched }),

  signOut: async () => {
    await supabase.auth.signOut();
    set({ session: null, user: null, hasFetchedUser: false });
    reset(); // Reset Mixpanel user
  },

  fetchUserData: async () => {
    const { session } = get();
    if (!session) {
      console.log("No session, cannot fetch user data");
      set({ isLoading: false });
      return;
    }

    console.log("Fetching user data from Supabase");
    set({ isLoading: true });

    try {
      const { data, error } = await supabase
        .from('users')
        .select(`
          id,
          full_name,
          email,
          job_title,
          subscription_status,
          subscription_tier,
          subscription_end_date,
          first_question_used,
          first_answer_used,
          stripe_customer_id
        `)
        .eq('id', session.user.id)
        .single();

      if (error) {
        throw error;
      }

      if (!data) {
        throw new Error("User not found");
      }

      console.log("Data retrieved from Supabase:", data);
      set({ user: data as User, isLoading: false, error: null });
    } catch (error) {
      console.error('Error fetching user data:', error);
      set({ 
        isLoading: false, 
        error: error instanceof Error ? error.message : 'An unknown error occurred',
        user: null
      });
    }
  },

  updateUserData: async (data: Partial<User>) => {
    const { session, user } = get();
    if (!session || !user) return;

    const { data: updatedData, error } = await supabase
      .from('users')
      .update(data)
      .eq('id', session.user.id)
      .single();

    if (error) {
      console.error('Error updating user data:', error);
    } else if (updatedData) {
      const typedUpdatedData = updatedData as Partial<User>;
      set({ user: { ...user, ...typedUpdatedData } });
    }
  },
}));