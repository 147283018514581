import React, { useState, useRef } from 'react';
import { Play, Pause, FileText } from 'lucide-react';

interface AudioDisplayProps {
  audioUrl: string;
  onTranscriptionClick: () => void;
}

const AudioDisplay: React.FC<AudioDisplayProps> = ({ audioUrl, onTranscriptionClick }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const togglePlayback = async () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        try {
          await audioRef.current.play();
        } catch (error) {
          console.error("Audio playback failed:", error);
        }
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleAudioEnded = () => {
    setIsPlaying(false);
  };

  return (
    <div className="flex items-center gap-6 ">
      <div className="flex items-center gap-3">
        <span className="text-base-content/70 font-medium">Listen to your answer</span>
        <button
          onClick={togglePlayback}
          className="btn btn-sm bg-base-300/10 hover:bg-base-200/70 gap-2"
        >
          {isPlaying ? (
            <Pause className="h-4 w-4" />
          ) : (
            <Play className="h-4 w-4" />
          )}
          {isPlaying ? 'Pause' : 'Play'}
        </button>
      </div>

      <div className="w-px h-6 bg-base-content/10" /> {/* Vertical divider */}

      <button
        onClick={onTranscriptionClick}
        className="btn btn-sm btn-ghost gap-2 text-base-content/70 hover:text-base-content"
      >
        <FileText className="h-4 w-4" />
        Read transcription
      </button>

      <audio
        ref={audioRef}
        src={audioUrl}
        onEnded={handleAudioEnded}
        className="hidden"
      />
    </div>
  );
};

export default AudioDisplay;