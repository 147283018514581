import React from 'react';
import { X } from 'lucide-react';

interface TranscriptionModalProps {
  isOpen: boolean;
  onClose: () => void;
  transcription: string;
}

const TranscriptionModal: React.FC<TranscriptionModalProps> = ({ isOpen, onClose, transcription }) => {
  if (!isOpen) return null;

  const handleBackdropClick = () => {
    onClose();
  };

  const handleModalClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <div 
      className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50"
      onClick={handleBackdropClick}
    >
      <div 
        className="bg-base-100 rounded-2xl w-full max-w-2xl mx-4 p-6 shadow-xl"
        onClick={handleModalClick}
      >
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Answer Transcription</h3>
          <button 
            onClick={onClose}
            className="btn btn-ghost btn-circle"
          >
            <X className="h-5 w-5" />
          </button>
        </div>
        <div className="prose max-w-none">
          <p className="text-base-content/80 leading-relaxed">
            {transcription}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TranscriptionModal;