import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';

// Initialize mixpanel
mixpanel.init('73df1feba42a6772ab02d5e1a80184bc', {
  debug: process.env.NODE_ENV !== 'production',
  track_pageview: true,
  persistence: 'localStorage'
});

// Utility function to handle tracking
export const track = (eventName: string, properties?: Record<string, any>) => {
  mixpanel.track(eventName, properties);
};

// Track page view
export const trackPageView = (path: string) => {
  track('Page View', {
    path,
    url: window.location.href
  });
};

// Identify user
export const identify = (userId: string, userProperties?: Record<string, any>) => {
  mixpanel.identify(userId);
  if (userProperties) {
    mixpanel.people.set(userProperties);
  }
};

// Reset user on logout
export const reset = () => {
  mixpanel.reset();
};

// Hook to initialize tracking
export const useTracking = (userId?: string | null, userProperties?: Record<string, any>) => {
  // Identify user when logged in
  useEffect(() => {
    if (userId) {
      identify(userId, userProperties);
    }
  }, [userId, userProperties]);
};