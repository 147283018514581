import React, { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';
import { Link } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import { ReactComponent as GoogleLogo } from '../Assets/google.svg';
import { ReactComponent as MicrosoftLogo } from '../Assets/microsoft.svg';
import { ReactComponent as StripeLogo } from '../Assets/stripe.svg';
import { ReactComponent as UberLogo } from '../Assets/uber.svg';
import { ReactComponent as CoinbaseLogo } from '../Assets/coinbase.svg';
import HowItWorksSection from '../Components/HowItWorksSection';
import Cone from '../Assets/Cone.png';
import { track } from '../lib/mixpanel';



const LandingPage: React.FC = () => {
    const [jobTitle, setJobTitle] = useState("Product Manager");
    const [opacity, setOpacity] = useState(1);

    const handleGetStartedClick = (location: 'hero' | 'footer') => {
        track('Get Started Clicked', {
            location: location,
            current_job_title: jobTitle, // Track which job title was showing when they clicked
            page: 'landing',
            url: window.location.href
        });
    };
    
    const jobs = [
        "Product Manager",
        "Software Engineer",
        "Data Analyst",
        "Growth Marketer",
        "Program Manager",
        "UI/UX Researcher",
        "Project Manager",
        "UX Researcher",
        "Data Scientist",
        "Customer Success"
    ];

    useEffect(() => {
        let currentIndex = 0;
        
        const interval = setInterval(() => {
            // Start fade out
            setOpacity(0);
            
            // Change text and fade in
            setTimeout(() => {
                currentIndex = (currentIndex + 1) % jobs.length;
                setJobTitle(jobs[currentIndex]);
                setOpacity(1);
            }, 750); // Wait for fade out to complete
            
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="min-h-screen bg-gradient-to-t from-stone-50 via-stone-300 to-amber-400 text-black">
            <Navbar />

           {/* Hero Section */}
           <div className="relative min-h-[calc(100vh-4rem)] flex items-center py-12 md:py-0">
                <div 
                    className="absolute inset-0 overflow-hidden" 
                    style={{
                        background: 'linear-gradient(to bottom, #fbbf24 0%, #fbbf24 30%, #fafaf9 100%)'
                    }}
                >
                    <img 
                        src={Cone} 
                        alt="" 
                        className="absolute right-0 transform translate-x-1/4 top-0 h-full object-contain opacity-60"
                    />
                </div>

                {/* Text Content */}
                <div className="relative z-10 container mx-auto px-4">
                    <div className="max-w-5xl">
                        <h1 className="text-4xl sm:text-5xl md:text-7xl lg:text-6xl font-bold leading-normal md:leading-tight">
                            Prepare for your{' '}
                            <span 
                                className="
                                    text-transparent 
                                    bg-clip-text 
                                    bg-gradient-to-b 
                                    from-amber-700 
                                    to-stone-800 
                                    inline-block
                                "
                                style={{
                                    minWidth: '7ch',
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    opacity: opacity,
                                    transition: 'opacity 750ms ease-in-out',
                                    minHeight: '1.2em',
                                    marginBottom: '0.1em',
                                }}
                            >
                                {jobTitle}
                            </span>
                            {' '}
                            <br />
                            interview with AI
                        </h1>
                        <div className="mt-8 md:mt-12">
                            <Link 
                                to="/auth" 
                                className="btn btn-lg bg-amber-400 text-stone-800 hover:bg-amber-300 border-none text-xl px-12"
                                onClick={() => handleGetStartedClick('hero')}
                            >
                                Get Started
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* How It Works Section */}
<HowItWorksSection />


            {/* Company Logos Section */}
            <div className="py-8 md:py-16 bg-stone-50">
                <div className="container mx-auto px-4 text-center">
                    <h2 className="text-xl md:text-2xl font-bold mb-6 md:mb-8 text-stone-800">Used by candidates who prep for interviews at</h2>
                    <div className="flex justify-center items-center flex-wrap gap-4 md:gap-8">
                        {/* SVG Company logos */}
                        <GoogleLogo className="w-24 h-12 md:w-32 md:h-16" />
                        <MicrosoftLogo className="w-24 h-12 md:w-32 md:h-16" />
                        <StripeLogo className="w-24 h-12 md:w-32 md:h-16" />
                        <CoinbaseLogo className="w-24 h-12 md:w-32 md:h-16" />
                        <UberLogo className="w-24 h-12 md:w-32 md:h-16" />
                    </div>
                </div>
            </div>

            {/* Features Section */}
            <div className="py-16 bg-stone-50">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center mb-8">Feel prepared by simulating the real thing</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        <div className="relative group">
                            <div className="absolute -inset-1 bg-gradient-to-r from-yellow-400 to-orange-300 rounded-lg blur opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200"></div>
                            <div className="relative px-7 py-6 bg-white ring-1 ring-gray-900/5 rounded-lg leading-none flex items-top justify-start space-x-6">
                                <div className="space-y-2">
                                    <p className="text-xl font-bold">AI-Powered Interviews</p>
                                    <p className="text-slate-800">Experience realistic interview scenarios with our advanced AI technology.</p>
                                </div>
                            </div>
                        </div>
                        <div className="relative group">
                            <div className="absolute -inset-1 bg-gradient-to-r from-yellow-400 to-orange-300 rounded-lg blur opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200"></div>
                            <div className="relative px-7 py-6 bg-white ring-1 ring-gray-900/5 rounded-lg leading-none flex items-top justify-start space-x-6">
                                <div className="space-y-2">
                                    <p className="text-xl font-bold">Personalized Feedback</p>
                                    <p className="text-slate-800">Receive detailed feedback and suggestions to improve your interview skills.</p>
                                </div>
                            </div>
                        </div>
                        <div className="relative group">
                            <div className="absolute -inset-1 bg-gradient-to-r from-yellow-400 to-orange-300 rounded-lg blur opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200"></div>
                            <div className="relative px-7 py-6 bg-white ring-1 ring-gray-900/5 rounded-lg leading-none flex items-top justify-start space-x-6">
                                <div className="space-y-2">
                                    <p className="text-xl font-bold">Industry-Specific Questions</p>
                                    <p className="text-slate-800">Practice with questions tailored to your industry and desired role.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* FAQ Section */}
            <div className="py-16 bg-stone-50">
                <div className="container mx-auto px-4">
                    <h2 className="text-3xl font-bold text-center mb-8">Frequently Asked Questions</h2>
                    <div className="space-y-4">
                        {[
                            { question: "What does Mockaroni do?", answer: "Allow you to practice mock interviews without having to bother your friends or search for example questions yourself. Mockaroni also analyzes your responses and provides actionable feedback so that you kill your next interview." },
                            { question: "Is this suitable for all job types and industries?", answer: "Yes, simply specify the job position you're a candidate for and our AI will determine the right questions to ask based on what's common in interviews for that industry. It works especially well for tech roles at popular Software Companies." },
                            { question: "How much does it cost to use Mockaroni AI?", answer: "You can try Mockaroni for free, and after your first question and answer, it costs $25 per week, $75 per month, and $175 per quarter." },
                            { question: "How often should I practice with Mockaroni?", answer: "We recommend practicing at least 2-3 times a week leading up to your interview. Consistent practice helps build confidence and improves your responses." },
                            { question: "Is my data kept confidential?", answer: "Yes, we take data privacy very seriously. Your data will never be shared with any third-parties." },
                        ].map((faq, index) => (
                            <details key={index} className="group bg-white shadow-lg rounded-lg">
                                <summary className="list-none cursor-pointer font-semibold text-lg px-6 py-4">
                                    <div className="flex items-center justify-between">
                                        {faq.question}
                                        <span className="transition group-open:rotate-180">
                                            <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path></svg>
                                        </span>
                                    </div>
                                </summary>
                                <p className="px-6 py-4 text-gray-700">
                                    {faq.answer}
                                </p>
                            </details>
                        ))}
                    </div>
                </div>
            </div>
            {/* Call to Action */}
            <div className="bg-stone-50 py-16">
                <div className="container mx-auto px-4 text-center">
                    <h2 className="text-3xl font-bold mb-4">Ready to ace your next interview?</h2>
                    <p className="mb-8">Sign up now and start practicing with Mockaroni AI.</p>
                    <div className="mt-8 md:mt-12">
                            <Link 
                                to="/auth" 
                                className="btn btn-lg bg-amber-400 text-stone-800 hover:bg-amber-300 border-none text-xl px-12"
                                onClick={() => handleGetStartedClick('footer')}
                            >
                                Get Started
                            </Link>
                        </div>
                </div>
            </div>

            {/* Updated Footer */}
            <footer className="footer footer-center p-10 bg-primary text-primary-content">
                <div className="space-y-4">
                    <p className="font-bold">
                        Mockaroni AI.<br />Helping you succeed since 2024
                    </p>
                    <p>Copyright © 2024 - All rights reserved</p>
                    <div className="flex gap-4 text-sm">
                        <Link 
                            to="/privacy" 
                            className="hover:underline hover:text-primary-200 transition-colors"
                        >
                            Privacy Policy
                        </Link>
                        <span>•</span>
                        <Link 
                            to="/termsOfService" 
                            className="hover:underline hover:text-primary-200 transition-colors"
                        >
                            Terms of Service
                        </Link>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default LandingPage;