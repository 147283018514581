import React, { useEffect, useRef, useState } from 'react';
import InputImage from '../Assets/Input.png';
import QuestionImage from '../Assets/Question.png';
import FeedbackImage from '../Assets/Feedback.png';

interface Step {
  id: 'input' | 'generate' | 'practice';
  title: string;
  image: string;
}

interface SectionRefs {
  input: React.RefObject<HTMLDivElement>;
  generate: React.RefObject<HTMLDivElement>;
  practice: React.RefObject<HTMLDivElement>;
}

const HowItWorksSection: React.FC = () => {
  const [activeSection, setActiveSection] = useState<Step['id']>('input');
  
  const sectionRefs: SectionRefs = {
    input: useRef<HTMLDivElement>(null),
    generate: useRef<HTMLDivElement>(null),
    practice: useRef<HTMLDivElement>(null)
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 2;

      Object.entries(sectionRefs).forEach(([key, ref]) => {
        if (ref.current) {
          const element = ref.current;
          const { top, bottom } = element.getBoundingClientRect();
          const elementCenter = top + (bottom - top) / 2;

          if (elementCenter > 0 && elementCenter < window.innerHeight) {
            setActiveSection(key as Step['id']);
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const steps: Step[] = [
    {
      id: 'input',
      title: 'Input',
      image: InputImage,
    },
    {
      id: 'generate',
      title: 'Generate',
      image: QuestionImage,
    },
    {
      id: 'practice',
      title: 'Practice',
      image: FeedbackImage,
    }
  ];

  return (
    <div className="py-16 bg-stone-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-16">How It Works</h2>
        
        <div className="relative max-w-6xl mx-auto">
          {/* Steps */}
          <div className="space-y-32">
            {steps.map((step) => (
              <div
                key={step.id}
                ref={sectionRefs[step.id]}
                className="relative flex flex-col md:flex-row items-center gap-8 md:gap-16"
              >
                {/* Title */}
                <div className="md:w-[30%] flex flex-col items-center md:items-end">
                  <h3 
                    className={`text-3xl font-bold transition-colors duration-500 ${
                      activeSection === step.id ? 'text-amber-500' : 'text-gray-400'
                    }`}
                  >
                    {step.title}
                  </h3>
                </div>

                {/* Image */}
                <div className="md:w-[70%] transition-all duration-500">
                  <img
                    src={step.image}
                    alt={step.title}
                    className="w-full max-w-xl rounded-lg shadow-lg transition-opacity duration-500"
                    style={{
                      opacity: activeSection === step.id ? 1 : 0.3
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorksSection;