import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-base-200">
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-white mb-8">Privacy Policy</h1>
        <div className="max-w-4xl bg-base-300/30 backdrop-blur-lg rounded-lg p-8 space-y-6 text-gray-200">
          <p className="text-sm text-gray-400">Last updated: November 11, 2024</p>

          <p>
            This Privacy Policy describes Our policies and procedures on the collection,
            use and disclosure of Your information when You use the Service and tells
            You about Your privacy rights and how the law protects You.
          </p>

          <p>
            We use Your Personal data to provide and improve the Service. By using the
            Service, You agree to the collection and use of information in accordance
            with this Privacy Policy.
          </p>

          <section>
            <h2 className="text-xl font-semibold text-white mt-8 mb-4">Interpretation and Definitions</h2>
            <h3 className="text-lg font-semibold text-white mb-2">Interpretation</h3>
            <p>
              The words of which the initial letter is capitalized have meanings defined
              under the following conditions. The following definitions shall have the same
              meaning regardless of whether they appear in singular or in plural.
            </p>
          </section>

          <section>
            <h3 className="text-lg font-semibold text-white mb-2">Definitions</h3>
            <p>For the purposes of this Privacy Policy:</p>
            <ul className="list-disc pl-6 mt-2 space-y-2">
              <li><strong className="text-white">Account</strong> means a unique account created for You to access our Service or parts of our Service.</li>
              <li><strong className="text-white">Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</li>
              <li><strong className="text-white">Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Mockaroni AI.</li>
              <li><strong className="text-white">Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</li>
              <li><strong className="text-white">Country</strong> refers to: New York, United States</li>
              <li><strong className="text-white">Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
              <li><strong className="text-white">Personal Data</strong> is any information that relates to an identified or identifiable individual.</li>
              <li><strong className="text-white">Service</strong> refers to the Website.</li>
              <li><strong className="text-white">Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company.</li>
              <li><strong className="text-white">Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself.</li>
              <li><strong className="text-white">Website</strong> refers to Mockaroni AI, accessible from <a href="https://www.mockaroni.ai" className="text-primary hover:text-primary-focus">www.mockaroni.ai</a></li>
              <li><strong className="text-white">You</strong> means the individual accessing or using the Service.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mt-8 mb-4">Collecting and Using Your Personal Data</h2>
            <h3 className="text-lg font-semibold text-white mb-2">Types of Data Collected</h3>
            
            <h4 className="text-base font-semibold text-white mb-2">Personal Data</h4>
            <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
            <ul className="list-disc pl-6 mt-2 space-y-2">
              <li>Email address</li>
              <li>First name and last name</li>
              <li>Usage Data</li>
            </ul>

            <h4 className="text-base font-semibold text-white mt-4 mb-2">Usage Data</h4>
            <p>Usage Data is collected automatically when using the Service. Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
          </section>

          <section>
            <h3 className="text-lg font-semibold text-white mt-6 mb-2">Retention of Your Personal Data</h3>
            <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our legal agreements and policies.</p>
          </section>

          <section>
            <h3 className="text-lg font-semibold text-white mt-6 mb-2">Security of Your Personal Data</h3>
            <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mt-8 mb-4">Children's Privacy</h2>
            <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mt-8 mb-4">Links to Other Websites</h2>
            <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mt-8 mb-4">Changes to this Privacy Policy</h2>
            <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mt-8 mb-4">Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, You can contact us:</p>
            <p className="mt-2">By email: <a href="mailto:contact@mockaroni.ai" className="text-primary hover:text-primary-focus">contact@mockaroni.ai</a></p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;