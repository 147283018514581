import React, { useState, useEffect } from 'react';
import { supabase } from '../lib/supabaseClient';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '../ZustandStores/userStore';
import { track } from '../lib/mixpanel';


const AuthenticatePage: React.FC = () => {
  const navigate = useNavigate();
  const { session, isLoading, setIsLoading } = useUserStore();
  const [error, setError] = useState<string | null>(null);
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  useEffect(() => {
    if (session) {
      navigate('/home');
    }
  }, [session, navigate]);

  const handleGoogleSignIn = async () => {
    console.log('Google Sign-In initiated');
    setIsAuthenticating(true);
    setError(null);

    // Track sign-in attempt
    track('Sign In Clicked', {
      provider: 'google',
      source: 'auth_page',
      timestamp: new Date().toISOString(),
      referrer: document.referrer,
      url_path: window.location.pathname,
    });

    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          //redirectTo: 'http://localhost:3000/auth',
          redirectTo: `${window.location.origin}/auth`,
          queryParams: {
            access_type: 'offline',
            prompt: 'select_account'
          }
        }
      });
      
      if (error) {
        // Track sign-in error
        track('Sign In Error', {
          provider: 'google',
          error_message: error.message,
          error_code: error.status || 'unknown',
          source: 'auth_page',
        });
        throw error;
      }
      
      // Track successful sign-in initiation
      track('Sign In Initiated', {
        provider: 'google',
        source: 'auth_page',
        timestamp: new Date().toISOString(),
      });
      
      console.log('Sign-in initiated successfully');
    } catch (error: any) {
      console.error('Error signing in with Google:', error);
      setError('Error signing in with Google. Please try again.');
      setIsAuthenticating(false);
    }
  };


  const tickerContent = "Common questions • Simulated environment • Actionable feedback • Track your progress • ";

  const reviews = [
    {
      quote: "The interview questions feel so realistic, and they're spoken to you in a human voice. It's as close to being in a real interview as it gets.",
      author: "Aashna P.",
      role: "Software Engineer"
    },
    {
      quote: "The feedback was spot-on and helped me improve my interview skills, which made me so confident when I actually had my interview.",
      author: "Michael R.",
      role: "Product Manager"
    },
    {
      quote: "Nothing is as satisfying as getting that perfect interview score.",
      author: "Jose L.",
      role: "Data Scientist"
    }
  ];

  return (
    <div className="min-h-screen flex flex-col items-center bg-stone-50 py-4">
      <h1 className="text-xl font-bold text-center text-gray-800 mb-2">Mockaroni AI</h1>
      
      <div className="w-full relative overflow-hidden bg-stone-50 py-2 mb-20">
        <div className="ticker-wrap">
          <div className="ticker">
            <span className="text-xl font-semibold ticker-content">{tickerContent.repeat(4)}</span>
          </div>
        </div>
        <div className="ticker-blur-left"></div>
        <div className="ticker-blur-right"></div>
      </div>

      
      <div className="flex flex-col items-center space-y-4 px-4 mb-20">
        <h2 className="text-3xl font-bold text-center text-gray-800">Practice makes perfect.</h2>
        
        {error && <p className="text-red-500">{error}</p>}
        <button
          onClick={handleGoogleSignIn}
          disabled={isLoading || isAuthenticating}
          className="btn btn-primary btn-lg font-semibold py-4 px-8 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-lg flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed transition duration-300 ease-in-out shadow-lg min-w-[300px]"
        >
          {isLoading || isAuthenticating ? (
            <svg className="animate-spin h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          ) : (
            <>
              <svg className="w-6 h-6 mr-4" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/>
                <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/>
                <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/>
                <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/>
                <path d="M1 1h22v22H1z" fill="none"/>
              </svg>
              Continue with Google
            </>
          )}
        </button>
      </div>
        

      {/* Reviews Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 max-w-6xl mx-auto px-4 mb-20">
          {reviews.map((review, index) => (
            <div 
              key={index}
              className="relative group bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
            >
              <div className="absolute -top-4 left-6 text-6xl text-amber-400 opacity-50">"</div>
              <div className="relative">
                <p className="text-gray-700 mb-4 italic">
                  {review.quote}
                </p>
                <div className="text-right">
                  <p className="font-semibold text-gray-900">{review.author}</p>
                  <p className="text-sm text-gray-600">{review.role}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      
      
    </div>
  );
};

export default AuthenticatePage;