import React, { useEffect } from 'react';
import { useQuestionStore } from '../ZustandStores/questionsStore';
import { useUserStore } from '../ZustandStores/userStore';
import Layout from '../Components/Layout';
import AllQuestionsQuestionCard from '../Components/AllQuestionsQuestionCard';

const AllQuestionsPage: React.FC = () => {
  const { user } = useUserStore();
  const { questions, fetchQuestions } = useQuestionStore();
  
  useEffect(() => {
    if (user) {
      fetchQuestions(user.id);
    }
  }, [user]);

  // Filter out questions with error messages
  const validQuestions = questions.filter(question => !question.error_message);

  return (
    <Layout>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-white mb-8">All Questions</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {validQuestions.map((question) => (
            <AllQuestionsQuestionCard key={question.id} question={question} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default AllQuestionsPage;



