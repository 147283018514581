import React from 'react';
import { Link } from 'react-router-dom';
import mockaroniLogo from '../Assets/mockaroni-logo.webp';

function Navbar() {
  return (
    <div className="fixed top-4 left-4 right-4 z-50 flex justify-between items-center">
      <div className="py-2 px-3 sm:px-4">
        <Link to="/" className="flex items-center hover:opacity-80 transition-opacity">
          <img src={mockaroniLogo} alt="Mockaroni Logo" className="h-8 sm:h-12 w-auto" />
          <span className="ml-2 text-base sm:text-lg font-semibold whitespace-nowrap">Mockaroni AI</span>
        </Link>
      </div>
      <div className="bg-base-100 rounded-2xl shadow-lg py-2 px-3 sm:px-4">
        <div className="flex items-center space-x-1.5 sm:space-x-2">
          <Link 
            to="/auth" 
            className="btn btn-secondary btn-sm hover:bg-button-hover rounded-full text-sm sm:text-base px-2 sm:px-4"
          >
            Log In
          </Link>
          <Link 
            to="/auth" 
            className="btn btn-primary btn-sm rounded-full text-sm sm:text-base px-2 sm:px-4"
          >
            Try for Free
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Navbar;