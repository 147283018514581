import React, { useState } from 'react';
import { Star } from 'lucide-react';
import FeedbackDisplay from './FeedbackDisplay';
import AudioDisplay from './AudioDisplay';
import TranscriptionModal from './TranscriptionModal';

interface AttemptDisplayProps {
  answer: {
    id: string;
    audio_url: string;
    transcription: string;
    feedback: {
      pros: string[];
      cons: string[];
      improvements: string[];
      overall_score: number;
    };
  };
  attemptNumber: number;
}

const AttemptDisplay: React.FC<AttemptDisplayProps> = ({ answer, attemptNumber }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="bg-base-300/50 backdrop-blur-sm rounded-3xl border border-base-content/5">
      <div className="p-8 space-y-6">
        {/* Header with Attempt # and Score */}
        <div className="flex items-center justify-between bg-base-100/30 backdrop-blur-sm p-4 rounded-2xl">
          <div className="flex items-center gap-6">
            <h3 className="text-lg font-medium text-base-content/70">Attempt {attemptNumber}</h3>
            <div className={`flex items-center gap-2 ${
              answer.feedback.overall_score >= 8 ? 'bg-success/10' : 
              answer.feedback.overall_score >= 6 ? 'bg-warning/10' : 
              'bg-error/10'
            } px-4 py-2 rounded-xl`}>
              <Star className="h-5 w-5 text-[#fbc02b]" fill="#fbc02b" />
              <span className={`text-lg font-bold ${
                answer.feedback.overall_score >= 8 ? 'text-success' : 
                answer.feedback.overall_score >= 6 ? 'text-warning' : 
                'text-error'
              }`}>
                {answer.feedback.overall_score}/10
              </span>
            </div>
          </div>
        </div>

        {/* Audio Player and Transcription Button Row */}
        <div className="bg-base-100/30 p-4 rounded-xl">
          <AudioDisplay 
            audioUrl={answer.audio_url} 
            onTranscriptionClick={() => setIsModalOpen(true)}
          />
        </div>

        {/* Feedback Cards */}
        <FeedbackDisplay feedback={answer.feedback} />

        {/* Transcription Modal */}
        <TranscriptionModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          transcription={answer.transcription}
        />
      </div>
    </div>
  );
};

export default AttemptDisplay;