import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const DNAProcessingAnimation = () => {
  const [currentText, setCurrentText] = useState('Processing your answer');
  const messages = [
    'Processing your answer',
    'Providing feedback',
    'Generating overall score'
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      if (currentIndex < messages.length - 1) {
        setCurrentIndex(prev => prev + 1);
      }
    }, 3000);

    return () => clearInterval(timer);
  }, [currentIndex]);

  // Create DNA strands
  const strandCount = 20;
  const strands = Array.from({ length: strandCount }, (_, i) => i);

  return (
    <div className="flex flex-col items-center justify-center p-8 min-h-[400px]">
      {/* DNA Helix */}
      <div className="relative w-40 h-40 mb-12">
        <div className="absolute inset-0 flex items-center justify-center">
          {strands.map((strand) => (
            <motion.div
              key={strand}
              className="absolute w-full h-1"
              animate={{
                rotateZ: [0, 360],
                scaleX: [1, 0.3, 1],
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                ease: "linear",
                delay: (strand / strandCount) * 3,
              }}
              style={{
                originX: 0.5,
                originY: 0.5,
              }}
            >
              {/* Left DNA node */}
              <motion.div
                className="absolute left-0 w-3 h-3 rounded-full"
                style={{ backgroundColor: '#fbc02b' }}
                animate={{
                  opacity: [1, 0.3, 1],
                  scale: [1, 0.8, 1],
                }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  delay: (strand / strandCount) * 3,
                }}
              />
              
              {/* Right DNA node */}
              <motion.div
                className="absolute right-0 w-3 h-3 rounded-full"
                style={{ backgroundColor: '#fbc02b' }}
                animate={{
                  opacity: [1, 0.3, 1],
                  scale: [1, 0.8, 1],
                }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  delay: (strand / strandCount) * 3,
                }}
              />
              
              {/* Connecting line */}
              <motion.div
                className="absolute inset-0 mx-3"
                style={{ 
                  backgroundColor: '#fbc02b',
                  opacity: 0.3,
                  height: '2px',
                }}
              />
            </motion.div>
          ))}
        </div>
      </div>

      {/* Text Animation */}
      <div className="h-8 relative overflow-hidden">
        <AnimatePresence mode="wait">
          <motion.div
            key={messages[currentIndex]}
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -50, opacity: 0 }}
            transition={{
              duration: 0.5,
              ease: "easeOut"
            }}
            className="text-center"
          >
            <span 
              className="text-lg font-semibold"
              style={{ color: '#fbc02b' }}
            >
              {messages[currentIndex]}
            </span>
          </motion.div>
        </AnimatePresence>
      </div>

      {/* Loading Dots */}
      <div className="flex space-x-2 mt-4">
        {[0, 1, 2].map((i) => (
          <motion.div
            key={i}
            className="w-2 h-2 rounded-full"
            style={{ backgroundColor: '#fbc02b' }}
            animate={{
              y: ["0%", "-100%", "0%"],
              opacity: [0.3, 1, 0.3],
            }}
            transition={{
              duration: 0.6,
              repeat: Infinity,
              ease: "easeInOut",
              delay: i * 0.2,
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default DNAProcessingAnimation;